import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { useUserAuth } from "../../../Setup/context/UserAuthContext";
import AnimatedPage from "../../AnimatedPage";
import "./ForgotPassword.css";
import {
  Container,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormInput,
  FormLabel,
  FormWrap,
} from "./ForgotPasswordElements";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { forgotPassword } = useUserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await forgotPassword(email);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <AnimatedPage>
      <Container>
        <FormWrap>
          <FormContent>
            {!success ? (
              <Form onSubmit={handleSubmit}>
                <FormH1>Enter your email</FormH1>
                {error && <Alert variant="danger">{error}</Alert>}
                <FormLabel htmlFor="for">Email</FormLabel>
                <FormInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                />
                <FormButton type="submit">Reset Password</FormButton>
              </Form>
            ) : (
              <h1 className="check-inbox">
                Please check your inbox (primary/spam) for a password reset
                link. <AiOutlineMail className="mail-icon" />
              </h1>
            )}
          </FormContent>
        </FormWrap>
      </Container>
    </AnimatedPage>
  );
};

export default ForgotPassword;
