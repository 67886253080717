import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  LogoutBtn,
  SidebarDonateLink
} from "./DropdownElements";
import { useNavigate } from "react-router";
import { useUserAuth } from "../../Setup/context/UserAuthContext";


const Dropdown = ({ isOpen, toggle }) => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {!user && (
          <SidebarMenu>
            <SidebarLink to="/" onClick={toggle}>
              Home
            </SidebarLink>
            <SidebarLink to="/who-we-are" onClick={toggle}>
              About
            </SidebarLink>
            <SidebarDonateLink
              href="https://donorbox.org/donate-now-266"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donate
            </SidebarDonateLink>
            <SidebarLink to="/contact" onClick={toggle}>
              Contact
            </SidebarLink>
          </SidebarMenu>
        )}
        {user && (
          <SidebarMenu>
            <SidebarLink to="/landing-page/dashboard" onClick={toggle}>
              Dashboard
            </SidebarLink>
            <SidebarLink to="/landing-page/resources" onClick={toggle}>
              Resources
            </SidebarLink>
            <SidebarLink to="/landing-page/faq" onClick={toggle}>
              FAQ
            </SidebarLink>
            <SidebarLink to="/landing-page/settings" onClick={toggle}>
              Settings
            </SidebarLink>
          </SidebarMenu>
        )}
        <SideBtnWrap>
          {!user && (
            <SidebarRoute to="/login" onClick={toggle}>
              Login
            </SidebarRoute>
          )}
          {user && <LogoutBtn onClick={handleLogOut}>Logout</LogoutBtn>}
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Dropdown;
