import React from "react";
import { FiAlertCircle } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Alert({ children, type }) {
  const className = `dashboard-alert ${type}-alert`;
  return (
    <>
      <div className={className}><FiAlertCircle size={20} className='alert-circle'/> {children}</div>
    </>
  );
}
