import React from "react";
import { NavLink } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="notfound-container">
      <h1 className="notfound-text">
        <span className="oops">Oops...</span> Looks like the page you're looking
        for is not here. Click{" "}
        <NavLink className="notfound-link" to="/">
          here
        </NavLink>{" "}
        to be redirected.
      </h1>
    </div>
  );
};

export default NotFound;
