import styled from "styled-components";
import { NavLink as LinkR } from "react-router-dom";

export const SideLink = styled(LinkR)`
  display: flex;
  align-items: center;
  place-content: flex-start;
  padding: 0.3rem 0.8rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: white;
  transition: 0.2s ease-in-out;
  margin-bottom: 2px;

  &.active {
    text-decoration: 3px solid orangered;
    color: orangered;
    background: white;
    border-radius: 20px;
    
  }

  &:hover {
    color: orangered;
    background-color: white;
    border-radius: 20px;
  }
`;

export const SideLogout = styled.button`
  display: flex;
  align-items: center;
  place-content: flex-start;
  border: none;
  border-radius: 20px;
  outline: none;
  width: 100%;
  padding: 0.3rem 0.8rem;
  cursor: pointer;
  background: transparent;
  color: white;
  transition: 0.2s ease-in-out;
  

  &:hover {
    color: orangered;
    background-color: white;
    border-radius: 20px;

  }
`;
