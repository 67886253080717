import React from "react";
import Alert from "./Alert";

export default function AlertList({ alerts }) {
  if (!alerts) {
    return;
  }

  return (
    <div>
      {alerts.map((alert) => {
        return (
          <Alert key={alert.text} type={alert.type}>
            {alert.text}
          </Alert>
        );
      })}
    </div>
  );
}
