import React from "react";
import { useUserAuth } from "../../../../Setup/context/UserAuthContext";
import AnimatedPage from "../../../AnimatedPage";
import "./Settings.css";
import SettingsBlock from "./SettingsBlock";

export const Settings = () => {
  const { user, userDoc } = useUserAuth();

  return (
    <AnimatedPage>
      <div>
        <AnimatedPage>
          <div className="dashboard-page__container">
            <div className="content-padder">
              <h1 className="landing-page__welcome">Settings</h1>
              <div className="landing-page-content__container">
                <SettingsBlock />
              </div>
            </div>
          </div>
        </AnimatedPage>
      </div>
    </AnimatedPage>
  );
};

export default Settings;
