// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_Uf0jPHD-5PGXdUF8qcekjiJdFD4VeKE",
  authDomain: "eatportal.firebaseapp.com",
  projectId: "eatportal",
  storageBucket: "eatportal.appspot.com",
  messagingSenderId: "543803715897",
  appId: "1:543803715897:web:10009e39e91779a25eb8e8",
  measurementId: "G-CEELFVJLQ0",
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const appAuth = getAuth(app);

export { appAuth };

// Get functions
const functions = getFunctions(app);

export const createUserFunc = httpsCallable(functions, "createUser");
export const inviteUser = httpsCallable(functions, "inviteUser");

// // Example create user func
// // Don't need to do all the
// // then and catch stuff
// createUserFunc({
//   full_name: "new user",
//   email: "email@email",
//   phone: "1444 your mom",
//   emergency_phone: "144 emergency"
// }).then((result) => {
//   console.log("Test Create User")
//   console.log(result)
// })
// .catch((error) => {
//   // Getting the Error details.
//   const code = error.code;
//   const message = error.message;
//   const details = error.details;
//   console.log(error)
// });
