import { useEffect, useState } from "react";
import { FaAngleDoubleDown } from "react-icons/fa";
import "../ScrollToTop/ScrollToTop.css";

const ScrollToBottom = () => {
  const [showScrollBottomButton, setShowScrollBottomButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setShowScrollBottomButton(true);
      } else {
        setShowScrollBottomButton(false);
      }
    });
  }, []);

  const scrollTop = (elementRef) => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {showScrollBottomButton && (
        <FaAngleDoubleDown className="bot-btn" onClick={scrollTop} />
      )}
    </div>
  );
};

export default ScrollToBottom;
