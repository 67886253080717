import React from "react";
import { useUserAuth } from "../../../../Setup/context/UserAuthContext";
import AnimatedPage from "../../../AnimatedPage";
import Alerts from "../../Alerts/Alerts";
import "./Dashboard.css";

const Dashboard = () => {
  const { userDoc } = useUserAuth();
  return (
    <div>
      <AnimatedPage>
        <div className="dashboard-page__container">
          <div className="content-padder">
            <h1 className="landing-page__welcome">
              Welcome,{" "}
              <span className="landing-page__name">
                {userDoc && userDoc.first_name}
              </span>
            </h1>
            <div className="landing-page-content__container">
              <Alerts userDoc={userDoc} />
            </div>
          </div>
        </div>
      </AnimatedPage>
    </div>
  );
};

export default Dashboard;
