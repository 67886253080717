import React from "react";
import { RiSurveyLine } from "react-icons/ri";
import Button from "../Button/Button.js";

export default function Survey({ userData, user, surveyURL }) {
  const href = `${surveyURL}#email=${user.email}&uid=${user.uid}&name=${userData.full_name}`;

  return (
    <div>
      {userData.mustFillOutSurvey === true && (
        <div className="todo-btns">
          {/* <div className="box-text">
            <RiSurveyLine
              className="dashboard-icon"
              color="orangered"
              size={35}
            />
            Take this month's survey to receive your payment!
          </div> */}
          <Button href={href}>
            Take Survey{" "}
            <RiSurveyLine
              className="dashboard-icon"
              color="orangered"
              size={35}
            />
          </Button>
        </div>
      )}
    </div>
  );
}
