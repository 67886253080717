import { Link, NavLink as LinkR } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: transparent;
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    transition: 0.8s all ease;
  }

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;

    &:hover {
      background: none;
    }
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;

  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  margin-left: 0px;

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const NavLogoImg = styled.img`
  height: 60px;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 820px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 40%);
    font-size: 2.5rem;
    cursor: pointer;
    color: orangered;
  }

  @media screen and (max-width: 500px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 40%);
    font-size: 2.5rem;
    cursor: pointer;
    color: orangered;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 10px;

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkR)`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: orangered;
    background: transparent;
  }

  &.active {
    color: orangered;
    border-bottom: 3px solid orangered;
  }
`;

export const NavLogin = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const NavLoginLink = styled(LinkR)`
  font-weight: bold;
  border-radius: 50px;
  background: orangered;
  color: white;
  white-space: nowrap;
  padding: 10px 22px;
  font-size: 16px;
  outline: none;

  &:hover {
    background: white;
    color: orangered;
  }
`;

export const DonateLink = styled.a`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: orangered;
  }
`;

export const NavLogoutButton = styled.div`
  font-weight: bold;
  border-radius: 50px;
  background: white;
  color: orangered;
  white-space: nowrap;
  padding: 10px 22px;
  font-size: 16px;
  outline: none;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: none;

  &:hover {
    background: orangered;
    color: white;
  }

  @media screen and (max-width: 820px) {
    display: none;
  }
`;
