import "animate.css/animate.min.css";
import "boxicons/css/boxicons.min.css";
import { AnimatePresence } from "framer-motion";
import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import InviteUsers from "./components/Dashboard/Admin/InviteUsers";
import Dashboard from "./components/Dashboard/Pages/Dashboard/Dashboard";
import { FAQ } from "./components/Dashboard/Pages/FAQ/FAQ";
import LandingPage from "./components/Dashboard/Pages/Landing Page/LandingPage";
import Resources from "./components/Dashboard/Pages/Resources/Resources";
import Settings from "./components/Dashboard/Pages/Settings/Settings";
import Footer from "./components/Footer/Footer";
import ForgotPassword from "./components/Pages/ForgotPassword/ForgotPassword";
import Hero from "./components/Pages/Hero/Hero";
import Home from "./components/Pages/Home/Home";
import NotFound from "./components/Pages/NotFound/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./Setup/context/UserAuthContext";
import LoadingSpinner from "./ui/LoadingSpinner";
import(/* webpackPreload: true */ "./fonts/ProximaNova-Regular.woff2");

function App() {
  const location = useLocation();

  useEffect(() => {
    // Enable logged out chat on login page
    if (location.pathname === "/login") {
      window.Intercom("boot", {
        app_id: "trvqrjzd",
      });
    } else if (location.pathname.includes("/landing-page")) {
    } else {
      window.Intercom("shutdown");
    }
  }, [location.pathname]);

  const Login = React.lazy(() => import("./components/Pages/Login/Login"));

  const WhoWeAre = React.lazy(() =>
    import("./components/Pages/Who We Are/WhoWeAre")
  );

  const Contact = React.lazy(() =>
    import("./components/Pages/Contact/Contact")
  );

  const SignUp = React.lazy(() => import("./components/Pages/SignUp/SignUp"));

  return (
    <div className="main-app">
      <AnimatePresence>
        <UserAuthContextProvider>
          <Home />
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Hero />} />
              <Route path="who-we-are" element={<WhoWeAre />} />
              <Route path="login" element={<Login />} />
              <Route path="contact" element={<Contact />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
              <Route
                path="/landing-page"
                element={
                  <ProtectedRoute>
                    <LandingPage />
                  </ProtectedRoute>
                }
              >
                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="resources"
                  element={
                    <ProtectedRoute>
                      <Resources />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <ProtectedRoute>
                      <Settings />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="faq"
                  element={
                    <ProtectedRoute>
                      <FAQ />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="invite-users"
                  element={
                    <AdminProtectedRoute>
                      <InviteUsers />
                    </AdminProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </Suspense>
          <Footer />
        </UserAuthContextProvider>
      </AnimatePresence>
    </div>
  );
}

export default App;
