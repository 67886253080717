import React from 'react';

function SettingsInput({ name, type, value, set, locked }) {

    const handleChange = (event) => {
        set(event.target.value)
    }

    return (
        <div className='setting-container form-group'>

            <label htmlFor={`${name}-form-input`}>
                {name}
            </label>

            <input
                className='form-control'
                id={`${name}-form-input`}
                type={type}
                value={value}
                onChange={handleChange}
                disabled={locked}
            />
        </div>
    )
}

export default SettingsInput