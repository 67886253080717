import React from "react";
import AnimatedPage from "../../../AnimatedPage";

export const FAQ = () => {
  const data = [
    {
      question: `How much money will I receive each month?`,
      answer: `$500`,
    },
    {
      question: `When will I receive the $500?`,
      answer: `After the completion of both survey and phone interview, money will be disbursed on the 15th each month.`,
    },
  ];

  return (
    <AnimatedPage>
      <div className="dashboard-page__container">
        <div className="content-padder">
          <h1 className="landing-page__welcome">FAQ</h1>
          <div className="resources-container"></div>
        </div>
      </div>
    </AnimatedPage>
  );
};
