import React from "react";
import { RiPhoneFill } from "react-icons/ri";
import Button from "../Button/Button";
import Alert from "./Alert";

export default function Calendly({ URL, userData }) {
  return (
    <div>
      {userData.mustScheduleInterview === true && (
        <div className="todo-btns">
          {/* <div className="box-text">
            <RiPhoneFill className='dashboard-icon' color="orangered" size={35} />
            Schedule your phone interview on Calendly.
          </div> */}
          <Button href={URL}>
            Schedule Interview{" "}
            <RiPhoneFill
              className="dashboard-icon"
              color="orangered"
              size={35}
            />
          </Button>
        </div>
      )}
    </div>
  );
}
