import React, { useState } from "react";
import { inviteUser } from "../../../Setup/apis/firebase";
import { useUserAuth } from "../../../Setup/context/UserAuthContext";
import AnimatedPage from "../../AnimatedPage";
import Button from "../Button/Button";
import SettingsInput from "../Pages/Settings/SettingsInput";

export default function InviteUsers() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    inviteUser({ email: email.toLowerCase() });
    setEmail("");
  };

  const { allowedEmails } = useUserAuth();

  const reversedAllowedEmails = allowedEmails.slice().reverse();

  return (
    <AnimatedPage>
      <div className="dashboard-page__container">
        <div className="content-padder">
          <h1 className="landing-page__welcome">Invite Users</h1>
          <div className="landing-page-content__container">
            <div className="white-block-no-zoom">
              <div style={{ width: "100%" }}>
                <form
                  onSubmit={handleSubmit}
                  style={{
                    margin: "auto",
                    width: "80%",
                  }}
                >
                  <p>
                    <b>Note: </b>It might take a second to see new users.
                  </p>
                  <br />
                  <SettingsInput
                    name="Email"
                    type="text"
                    value={email}
                    set={setEmail}
                  />
                  <Button onClick={handleSubmit}>Invite</Button>
                </form>
                <br></br>
                <div
                  style={{
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  <ol style={{ padding: "20px" }}>
                    {reversedAllowedEmails.map((thisEmail, index) => (
                      <li>
                        <div
                          key={thisEmail + String(index)}
                          style={{
                            margin: "auto",
                            "margin-top": "10px",
                            // width: 'fit-content',
                            padding: "12px",
                            background: "#0000ff99",
                            "border-radius": "10px",
                            color: "white",
                          }}
                        >
                          {thisEmail}
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
