import React, { useState, useEffect } from 'react'
import { useUserAuth } from '../../../../Setup/context/UserAuthContext'
import { SideLink } from "./SidebarElements";

const adminSidebarItems = [
    {
        display: "Invite Users",
        icon: <i className="bx bx-clipboard"></i>,
        to: "invite-users",
        section: "",
    },
];


export default function AdminSidebar() {

    const { user, userDoc, admins } = useUserAuth()
    
    if (userDoc && admins && admins.includes(userDoc.email)) {
        return (
            <div>
                {

                    adminSidebarItems.map((item, index) => (
                        <SideLink
                            className={({ isActive }) =>
                                isActive ? "active-class" : "not-active-class"
                            }
                            to={item.to}
                            key={index}
                        >
                            <div className="sidebar__menu__item">
                                <div className="sidebar__menu__item__icon">{item.icon}</div>
                                <div className="sidebar__menu__item__text">{item.display}</div>
                            </div>
                        </SideLink>
                    ))
                }
            </div>
        )
    }


}
