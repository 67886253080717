import React from "react";
import { useUserAuth } from "../../../Setup/context/UserAuthContext";
import Calendly from "./Calendly";
import Survey from "./Survey";
import "./Todo.css";

const typeform_url = "https://nsnpu6n0zo4.typeform.com/to/Exd7DyZK";
const calendly_url = 'https://www.google.com?q="calendly"';

export default function Todo() {
  const { user, userDoc } = useUserAuth();

  if (!userDoc) {
    return;
  }

  return (
    <div className="todo-grid">
      {"mustFillOutSurvey" in userDoc && (
        <Survey user={user} userData={userDoc} surveyURL={typeform_url} />
      )}
      {"mustScheduleInterview" in userDoc && (
        <Calendly URL={calendly_url} userData={userDoc} />
      )}
    </div>
  );
}
