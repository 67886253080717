import "./Footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-container">
      <ul className="footer-list">
        <li className="footer-list__item">
          <a
            href="https://www.facebook.com/EquityAndTransformation/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaFacebook size={18} />
          </a>
        </li>
        <li className="footer-list__item">
          <a
            href="https://www.instagram.com/eatorgchicago/?hl=en"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaInstagram size={18} />
          </a>
        </li>
        <li className="footer-list__item">
          <a
            href="https://www.linkedin.com/company/equity-and-transformation/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaLinkedin size={18} />
          </a>
        </li>
        <li className="footer-list__item">
          <a
            href="https://twitter.com/EATOrgChicago"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaTwitter size={18} />
          </a>
        </li>
        <li className="footer-list__item">
          <a
            href="https://www.youtube.com/channel/UCN6xCBzIg_OPfDw13bDNnPw"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaYoutube size={18} />
          </a>
        </li>
      </ul>
      <p className="copyright">
        {" "}
        © 2020 Equity and Transformation.{" "}
        <span className="privacy-policy">
          <a
            rel="noreferrer"
            href="https://docs.google.com/document/d/10ymKNdAoK9EENb8Hn1blKppCpfsXIHvV1QwEMIR3zu0/edit?usp=sharing"
            target="_blank"
          >
            Privacy Policy.
          </a>
        </span>
      </p>
    </div>
  );
};

export default Footer;
