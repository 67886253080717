import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router";
import EatLogo from "../../images/eatlogotxt.webp";
import { useUserAuth } from "../../Setup/context/UserAuthContext";
import "./Navbar.css";
import {
  DonateLink,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogin,
  NavLoginLink,
  NavLogo,
  NavLogoImg,
  NavLogoutButton,
  NavMenu,
} from "./NavbarElements";

const Navbar = ({ toggle }) => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (err) {
      console.log(err.message);
    }
  };

  let navClass = "";

  if (user) {
    navClass = "nav-background-none";
  }

  return (
    <>
      <Nav className={navClass}>
        <NavbarContainer>
          {!user && (
            <NavLogo to="/">
              <NavLogoImg src={EatLogo}></NavLogoImg>
            </NavLogo>
          )}
          {user && (
            <NavLogo className="nav-background" to="/landing-page/dashboard">
              <NavLogoImg src={EatLogo}></NavLogoImg>
            </NavLogo>
          )}
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          {!user && (
            <NavMenu>
              <NavItem>
                <NavLinks to="who-we-are">ABOUT</NavLinks>
              </NavItem>
              <NavItem>
                <DonateLink
                  href="https://donorbox.org/donate-now-266"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DONATE
                </DonateLink>
              </NavItem>
              <NavItem>
                <NavLinks to="contact">CONTACT</NavLinks>
              </NavItem>
            </NavMenu>
          )}
          {user && ""}
          <NavLogin>
            {!user && <NavLoginLink to="login">LOGIN</NavLoginLink>}
            {user && (
              <NavLogoutButton onClick={handleLogOut}>LOGOUT</NavLogoutButton>
            )}
          </NavLogin>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
