import React from 'react'
import ButtonStyle from './ButtonStyle'

export default function ButtonLink({ href, children, deactivated }) {
    if (deactivated) {
        return (
            <ButtonStyle deactivated={deactivated}>
                {children}
            </ButtonStyle>
        )
    }
    return (
        <a href={href} target="_blank" rel="noreferrer noopener">
            <ButtonStyle deactivated={deactivated}>
                {children}
            </ButtonStyle>
        </a>
    )
}
