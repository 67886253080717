import React from "react";
import { useNavigate } from "react-router";
import EatLogo from "../../../../images/eatlogotxt.webp";
import { useUserAuth } from "../../../../Setup/context/UserAuthContext";
import AdminSidebar from "./AdminSidebar";
import "./Sidebar.scss";
import { SideLink, SideLogout } from "./SidebarElements";

const sidebarNavItems = [
  {
    display: "Dashboard",
    icon: <i className="bx bx-home"></i>,
    to: "dashboard",
    section: "",
  },
  {
    display: "Resources",
    icon: <i className="bx bx-star"></i>,
    to: "resources",
    section: "resources",
  },
  {
    display: "FAQ",
    icon: <i className="bx bx-question-mark"></i>,
    to: "faq",
    section: "",
  },
  {
    display: "Settings",
    icon: <i className="bx bx-user"></i>,
    to: "settings",
    section: "",
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img className="eatlogotxt" src={EatLogo}></img>
      </div>
      <div className="sidebar__menu">
        <div className="sidebar__menu__indicator"></div>
        {sidebarNavItems.map((item, index) => (
          <SideLink
            className={({ isActive }) =>
              isActive ? "active-class" : "not-active-class"
            }
            to={item.to}
            key={index}
          >
            <div className="sidebar__menu__item">
              <div className="sidebar__menu__item__icon">{item.icon}</div>
              <div className="sidebar__menu__item__text">{item.display}</div>
            </div>
          </SideLink>
        ))}
        <AdminSidebar />
        <SideLogout onClick={handleLogOut}>
          <div className="sidebar__menu__item">
            <div className="sidebar__menu__item__icon">
              <i className="bx bx-power-off"></i>
            </div>
            <div className="sidebar__menu__item__text">Logout</div>
          </div>
        </SideLogout>
      </div>
      <div>
        {/* <button
          className="log-in__button hvr-shutter-out-vertical-log-in"
          // onClick={handleLogOut}
          type="button"
        >
          LOGOUT
        </button> */}
      </div>
    </div>
  );
};

export default Sidebar;
