import React from "react";
import "./button.css";

export default function ButtonStyle({ children, deactivated }) {
  var className = `eat-btn`;

  if (deactivated) {
    className += " deactivated";
  } else {
    className += "";
  }

  return <div className={className}>{children}</div>;
}
