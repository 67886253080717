import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../Setup/context/UserAuthContext";

const AdminProtectedRoute = ({ children }) => {
  const { user, userDoc, admins } = useUserAuth();
  if (!user || !('uid' in user) || !userDoc || !admins || !admins.includes(userDoc.email)) {
    return <Navigate to="/landing-page/dashboard" />;
  }
  return children;
};

export default AdminProtectedRoute;