import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { BsDot } from "react-icons/bs";
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import FtxLogo from "../../../images/ftxlogo.webp";
import { useUserAuth } from "../../../Setup/context/UserAuthContext";
import AnimatedPage from "../../AnimatedPage";
//import Button from "../Button/Button";
import "./alerts.css";
import Announcements from "./Announcements";
import Todo from "./Todo";

export default function Alerts() {
  const { userDoc } = useUserAuth();
  const date = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (!userDoc) {
    return;
  }
  let completed = "";
  if (!userDoc["mustFillOutSurvey"] && !userDoc["mustScheduleInterview"]) {
    completed = (
      <li className="todo-item">
        <>
          <BsDot />
          You're all set for your next payment!
          <GrCheckboxSelected />
        </>
      </li>
    );
  }
  return (
    <div className="alerts-container">
      <AnimatedPage>
        <div className="payment-container">
          <div className="dashboard-alert upcoming-container">
            {date.getDate() <= 15 && (
              <>
                <h2 className="upcoming-header">
                  Upcoming{" "}
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    size="md"
                    className="fa-flip alert-circle"
                  />
                </h2>
                <div className="upcoming-text">
                  Your next payment is coming on{" "}
                  <span className="payment-date">
                    {monthNames[date.getMonth()]} 15
                  </span>
                </div>
              </>
            )}
            {date.getDate() > 15 && (
              <>
                <h2 className="upcoming-header">
                  Upcoming{" "}
                  <FontAwesomeIcon
                    icon={faDollarSign}
                    size="md"
                    className="fa-flip alert-circle"
                  />
                </h2>
                <div className="upcoming-text">
                  Your next payment is coming on{" "}
                  <span className="payment-date">
                    {monthNames[date.getMonth() + 1]} 15
                  </span>
                </div>
              </>
            )}
            <ul className="todo-list">
              <li className="todo-item">
                <>
                  <BsDot /> Take survey{" "}
                  {userDoc["mustFillOutSurvey"] ? (
                    <GrCheckbox />
                  ) : (
                    <GrCheckboxSelected />
                  )}
                </>
              </li>
              <li className="todo-item">
                <>
                  <BsDot /> Schedule interview{" "}
                  {userDoc["mustScheduleInterview"] ? (
                    <GrCheckbox />
                  ) : (
                    <GrCheckboxSelected />
                  )}
                </>
              </li>
              {completed}
            </ul>
          </div>

          <div className="ftx-container dashboard-alert  grid-2">
            <h2>
              <img className="ftx-logo" alt="FTX LOGO" src={FtxLogo}></img>
            </h2>
            <div className="ftx-text">
              To access more information regarding payments, payment methods,
              balances, and more, please access your account at the FTX Portal.
            </div>
            <a
              className="ftx-link"
              href="https://www.ftx.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              FTX Portal
            </a>
          </div>
        </div>
        <Todo />
        <Announcements />
      </AnimatedPage>
    </div>
  );
}
