import React, { useState } from "react";
import { useUserAuth } from "../../../../Setup/context/UserAuthContext";
import AnimatedPage from "../../../AnimatedPage";
import Button from "../../Button/Button";
import "./MustVerifyEmail.css";
import {
  Container,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormInput,
  FormLabel,
  FormWrap,
} from "./MustVerifyEmailElements";

function MustVerifyEmail() {
  const [deactivated, setDeactivated] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { logOut, verifyEmail } = useUserAuth();
  const resend = async () => {
    setDeactivated(true);
    try {
      await verifyEmail();
      setMessage("Email Sent");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <AnimatedPage>
        <Container>
          <FormContent>
            <FormWrap>
              <FormH1>
                Email verification has been sent. Please check your email/spam
                to finalize your account.
              </FormH1>
              <div className="verify-section">
                <div className="verify-button">
                  <Button onClick={resend} deactivated={deactivated}>
                    Resend email
                  </Button>
                </div>
                <p className="soft-text">{message}</p>
                <p className="error-text">{error}</p>
              </div>
              <div>
                <p class="verify-logout" onClick={logOut}>
                  Log Out
                </p>
              </div>
            </FormWrap>
          </FormContent>
        </Container>
      </AnimatedPage>
    </div>
  );
}

export default MustVerifyEmail;
