import {
  createUserWithEmailAndPassword,
  inMemoryPersistence,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { appAuth, db } from "../apis/firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  // Store user document data
  const [userDoc, setUserDoc] = useState({});
  const [userDocRef, setUserDocRef] = useState(null);
  const [allowedEmails, setAllowedEmails] = useState([]);
  const [admins, setAdmins] = useState(null);

  function logIn(email, password) {
    setPersistence(appAuth, inMemoryPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return signInWithEmailAndPassword(appAuth, email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    return signInWithEmailAndPassword(appAuth, email, password);
  }

  function signUp(email, password) {
    return createUserWithEmailAndPassword(appAuth, email, password);
  }
  function logOut() {
    return signOut(appAuth);
  }

  function forgotPassword(email) {
    return sendPasswordResetEmail(appAuth, email, {
      url: "http://localhost:3000/login",
    });
  }
  function verifyEmail() {
    console.log(`${window.location.origin}/landing-page/dashboard`);
    return sendEmailVerification(appAuth.currentUser, {
      url: `${window.location.origin}/landing-page/dashboard`,
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(appAuth, (currentuser) => {
      // console.log("Auth", currentuser);
      setUser(currentuser);
    });

    const unsub2 = onSnapshot(doc(db, "globals", "allowedEmails"), (doc) => {
      let data = doc.data();
      setAllowedEmails(data.emails);
    });

    const adminsUnsub = onSnapshot(doc(db, "globals", "admins"), (doc) => {
      let data = doc.data();
      setAdmins(data.emails);
    });

    return () => {
      unsubscribe();
      unsub2();
      adminsUnsub();
    };
  }, []);

  useEffect(() => {
    if (user == null || !("uid" in user)) {
      setUserDoc({});
      setUserDocRef(null);
      window.Intercom("shutdown");
      return;
    }

    const unsub1 = onSnapshot(doc(db, "users", user.uid), (doc) => {
      let data = doc.data();
      setUserDoc(data);
      setUserDocRef(doc.ref);
      //console.log("Global user data");
      //console.log(doc.data());

      if (!doc.exists || !("uid" in user) || !user.emailVerified) {
        return;
      }

      // Intercom integration
      window.Intercom("boot", {
        app_id: "trvqrjzd",
        email: user.email,
        user_id: user.uid,
        name: data.full_name,
        phone: data.phone,
        "emergency phone": data.emergency_phone,
        created_at: Math.floor(new Date(user.created_at).getTime() / 1000),
      });
    });

    return () => {
      unsub1();
    };
  }, [user]);

  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        forgotPassword,
        userDoc,
        userDocRef,
        verifyEmail,
        allowedEmails,
        admins,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
