import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Dropdown from "../../Dropdown/Dropdown";
import { UserAuthContextProvider } from "../../../Setup/context/UserAuthContext";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <UserAuthContextProvider>
        <Navbar toggle={toggle} />
        <Dropdown isOpen={isOpen} toggle={toggle} />
      </UserAuthContextProvider>
    </>
  );
};

export default Home;
