import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../../Setup/context/UserAuthContext";
import AnimatedPage from "../../../AnimatedPage";
import MustVerifyEmail from "../MustVerifyEmail/MustVerifyEmail";
import Sidebar from "../Sidebar/Sidebar";
import "./LandingPage.css";

export const LandingPage = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (user != null && user.uid != null && !user.emailVerified) {
      navigate("/signup");
    }
  }, [user, navigate]);

  return (
    <AnimatedPage>
      <div className="landing-page__container">
        <Sidebar />
        <div className="scrollable">
          <Outlet />
        </div>
      </div>
    </AnimatedPage>
  );
};

export default LandingPage;
