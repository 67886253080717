import React, { useState, useEffect } from "react";
import { db } from "../../../Setup/apis/firebase";
import { collection, doc, onSnapshot } from "firebase/firestore";
import Alert from "./Alert";
import AlertList from "./AlertList";

export default function Announcements() {
  const [announcements, setAnnouncements] = useState([]);
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "globals", "announcements"), (doc) => {
      setAnnouncements(doc.data().items);
    });

    return unsub;
  }, []);

  return <AlertList alerts={announcements} />;
}
