import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll/dist/js/components";
import AboutTwoPic from "../../../images/abouttwopic.webp";
import AnimatedPage from "../../AnimatedPage";
import "./AboutTwo.css";

const AboutTwo = () => {
  return (
    <AnimatedPage>
      <div className="abouttwo-page__container">
        <div className="abouttwo-text__container">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <h2 className="abouttwo-text__header">
              <span className="animate-character">
                Why formerly incarcerated people?
              </span>
            </h2>
          </AnimationOnScroll>
          <div className="abouttwo-text__paragraph">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animateOut="animate__fadeOut"
            >
              <p>
                We have continuously witnessed system-impacted individuals face
                significant barriers in their everyday lives. They are shut out
                of the workforce, forced into informality, and criminalized for
                surviving. These systemic inequalities often lead to extreme
                poverty and additional periods of incarceration. The CFF is an
                intervention to help returning residents overcome these hurdles
                through financial assistance and education.
              </p>

              <br></br>

              <p>
                We want to empower people to live their lives how they see best
                by giving them the means to help move themselves forward in an
                exceedingly capital-centric world.
              </p>
            </AnimationOnScroll>
            <br></br>
          </div>

          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
          >
            <h2 className="abouttwo-text__header">
              <span className="animate-character">Why guaranteed income?</span>
            </h2>
          </AnimationOnScroll>

          <div className="abouttwo-text__paragraph">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animateOut="animate__fadeOut"
            >
              <p>
                Unconditional cash meets people’s most urgent needs, which isn’t
                always limited to food, housing, and health security. Removing
                all restrictions mitigates unprecedented costs and provides a
                safety net amid life’s chaotic events. We want to ensure that
                our recipients can use the money whenever and however they need
                it.
              </p>

              <br></br>

              <p>
                A guaranteed income of $500/month isn’t going to make anyone
                rich, but it will provide a lifeline to someone on the verge of
                drowning. $500/month allows folks to focus on their families,
                careers, and wellbeing without having to worry about unexpected
                expenses.{" "}
              </p>
            </AnimationOnScroll>
            <br></br>
          </div>
        </div>
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          animateOut="animate__fadeOut"
        >
          <div className="abouttwo-side__pic__container">
            <img
              className="abouttwo-side__pic"
              alt="EAT Member Rally"
              src={AboutTwoPic}
            ></img>
          </div>
        </AnimationOnScroll>
      </div>
    </AnimatedPage>
  );
};

export default AboutTwo;
