import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 100vh;
  position: relative;
  z-index: 1;
`;

export const MuteBtn = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  color: white;
  transform: translate(-80%, 250%);
  cursor: pointer;

  @media screen and (max-width: 500px) {
    transform: translate(-85%, 220%);
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 90px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  @media screen and (max-width: 500px) {
    border-radius: 80px;
    padding: 70px 30px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 30px;
    transition: all 0.3s ease-out;
  }
`;

export const HeroH1 = styled.h1`
  color: white;
  font-size: 48px;
  text-align: center;
  text-shadow: 2px 2px black;

  @media screen and (max-width: 1440px) {
    font-size: 43px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 500px) {
    font-size: 34px;
  }

  @media screen and (max-width: 320px) {
    font-size: 30px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 24px;
  text-align: center;
  text-shadow: 2px 2px black;
  white-space: nowrap;

  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }

  @media screen and (max-width: 370px) {
    white-space: pre-wrap;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ApplyHereBtn = styled.button`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#01BF71" : "orangered")};
  cursor: pointer;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "white")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  text-decoration: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: line-through;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "white")};
    color: orangered;
  }
`;
export const LearnMoreBtn = styled.button`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#01BF71" : "#010606")};
  cursor: pointer;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 25px")};
  color: ${({ dark }) => (dark ? "#010606" : "white")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "orangered")};
  }
`;
