import React from "react";
import Button from "../../Button/Button";
import "./Resources.css";

function Resource({ title, href, children }) {
  return (
    <div className="resource">
      <div className="white-block">
        <div className="resource-inner">
          <div>
            <h3>{title}</h3>

            <p>{children}</p>
          </div>
          <div className="resource-button">
            <Button href={href}>
              <div className="resource-button__grid">
                Learn more
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                </svg>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
