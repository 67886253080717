import React from "react";
import ButtonLink from "./ButtonLink";
import ButtonStyle from "./ButtonStyle";

export default function Button({ deactivated, onClick, children, href }) {
  if (href != null) {
    return (
      <ButtonLink deactivated={deactivated} href={href}>
        {children}
      </ButtonLink>
    );
  }

  return (
    <div
      className="eat-btn-container"
      deactivated={deactivated}
      onClick={deactivated ? null : onClick}
    >
      <ButtonStyle deactivated={deactivated}>{children}</ButtonStyle>
    </div>
  );
}
