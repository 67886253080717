import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from "react-icons/bs";
import VideoPoster from "../../../images/videoposter.webp";
//import Video from "../../../videos/video.mp4";
import Video2 from "../../../videos/video2.m4v";
import AnimatedPage from "../../AnimatedPage";
import ScrollToBottom from "../../ScrollToBottom/ScrollToBottom";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import About from "../About/About";
import AboutTwo from "../AboutTwo/AboutTwo";
import {
  ApplyHereBtn,
  ArrowForward,
  ArrowRight,
  HeroBg,
  HeroBtnWrapper,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  LearnMoreBtn,
  MuteBtn,
  VideoBg,
} from "./HeroElements";
import "./MainSlide.scss";

const Hero = () => {
  const [hover, setHover] = useState(false);
  const [muted, setMuted] = useState(true);
  const videoRef = useRef(null);

  const setMute = () => {
    setMuted(!muted);
  };

  const onHover = () => {
    setHover(!hover);
  };

  useEffect(() => {
    !muted ? (videoRef.current.muted = false) : (videoRef.current.muted = true);
  }, [muted]);

  const learnMore = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <AnimatedPage>
      <HeroContainer>
        <MuteBtn onClick={setMute}>
          {muted ? (
            <BsFillVolumeMuteFill size="30" />
          ) : (
            <BsFillVolumeUpFill size="30" />
          )}
        </MuteBtn>
        <HeroBg>
          <VideoBg
            id="videoElement"
            playsInline
            autoPlay
            poster={VideoPoster}
            loop={false}
            controls={false}
            muted
            ref={videoRef}
            src={Video2}
            type="video/mp4"
          />

          {/* {muted ? (
            <VideoBg
              playsInline
              poster={VideoPoster}
              autoPlay
              loop
              src={Video}
              muted
              // ref={videoRef}
              type="video/mp4"
            />
          ) : (
            <VideoBg
              playsInline
              poster={VideoPoster}
              autoPlay
              loop
              src={Video}
              type="video/mp4"
            />
          )} */}
        </HeroBg>
        <HeroContent>
          <HeroH1>
            Are you eligible to{" "}
            <span className="heroh1-wrap">
              receive
              <div className="count-up__container">
                $
                <CountUp
                  className="count-up__fivehundred"
                  start={0}
                  end={500}
                  duration={3}
                ></CountUp>
              </div>
              a month?
            </span>
          </HeroH1>
          <HeroP>Applications are now closed.</HeroP>
          <HeroBtnWrapper>
            <a
            // href="https://ftx.chicagofuturefund.org/program/apply"
            // target="_blank"
            // rel="noopener noreferrer"
            >
              <ApplyHereBtn>Apply Here</ApplyHereBtn>
            </a>
            <LearnMoreBtn
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              type="button"
              onClick={() => scrollToSection(learnMore)}
            >
              Learn More {hover ? <ArrowForward /> : <ArrowRight />}
            </LearnMoreBtn>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>

      <div ref={learnMore}>
        <About />
      </div>

      <AboutTwo />

      <ScrollToTop />
      <ScrollToBottom />
    </AnimatedPage>
  );
};

export default Hero;
