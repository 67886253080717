import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll/dist/js/components";
import AboutSidePic from "../../../images/aboutsidepic.webp";
import AnimatedPage from "../../AnimatedPage";
import "./About.css";

const About = () => {
  return (
    <AnimatedPage>
      <div className="about-page__container">
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          animateOut="animate__fadeOut"
        >
          <div className="about-side__pic__container">
            <img
              className="about-side__pic"
              src={AboutSidePic}
              alt="Street Pawnshop Loans"
            ></img>
          </div>
        </AnimationOnScroll>
        <div className="about-text__container">
          {/* <h2 className="about-header">Chicago Future Fund</h2> */}
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
          >
            <h2 className="about-text__header">
              <span className="animate-character">
                Equity and Transformation
              </span>{" "}
              is giving $500/month to 100 formerly incarcerated individuals for
              one year.
            </h2>
          </AnimationOnScroll>
          <br></br>
          <div className="about-text__paragraph">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animateOut="animate__fadeOut"
            >
              <p>
                The Chicago Future Fund is EAT’s guaranteed income program for
                formerly incarcerated people. In 2021, EAT launched the Chicago
                Future Fund, giving $500/month to 30 system-impacted individuals
                in West Garfield Park. Now, we’re expanding the program.
              </p>

              <br></br>

              <p>
                This round of the Chicago Future Fund will give $500/month to
                100 formerly incarcerated residents of West Garfield Park,
                Englewood, and Austin. This program is supported by EAT’s
                mission to build social and economic equity for system-impacted
                folks living in Chicago.
              </p>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default React.forwardRef(About);
