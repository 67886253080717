import { updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../../Setup/context/UserAuthContext";
import Button from "../../Button/Button";
import SettingsInput from "./SettingsInput";

function SettingsBlock() {
  // Settings state
  const [locked, setLocked] = useState(true);
  const [success, setSuccess] = useState(false);

  const { user, userDoc, userDocRef } = useUserAuth();

  // Displayed values
  const [phone, setPhone] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone2, setPhone2] = useState(null); // Emergency phone

  // Keep data up to date but only
  // when user or state changes
  useEffect(() => {
    if (!noUser(user, userDoc) && locked) {
      setPhone(userDoc.phone);
      setName(userDoc.full_name);
      setEmail(userDoc.email);
      setPhone2(userDoc.emergency_phone);
    }
  }, [user, userDoc, locked]);

  // Return loading if there is not user auth
  if (noUser(user, userDoc)) {
    return <div className="white-block">Loading ...</div>;
  }

  const lock = () => {
    setLocked(true);
    setSuccess(false);
  };

  const unlock = () => {
    setLocked(false);
  };

  const save = () => {
    lock();
    setSuccess(true);
    updateDoc(userDocRef, {
      phone: phone,
      full_name: name,
      email: email,
      emergency_phone: phone2,
    });
  };

  return (
    <div className="white-block-no-zoom">
      <div id="settings-block">
        {success && (
          <div className="saved-settings">Your settings have been saved.</div>
        )}
        <div id="settings-container">
          <SettingsInput
            name="Phone Number"
            value={phone}
            type="text"
            set={setPhone}
            locked={locked}
          />
          <SettingsInput
            name="Contact Email"
            value={email}
            type="email"
            set={setEmail}
            locked={locked}
          />
          <SettingsInput
            name="Emergency Phone Number"
            value={phone2}
            type="text"
            set={setPhone2}
            locked={locked}
          />
        </div>
        <div id="settings-controls">
          {locked && <Button onClick={unlock}>Edit</Button>}
          {!locked && (
            <div>
              <Button onClick={save}>Save</Button>
              <br />
              <Button onClick={lock}>Cancel</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function noUser(user, userDoc) {
  if (user == null || userDoc == null) {
    return true;
  }
  if (isEmpty(user) || isEmpty(userDoc)) {
    return true;
  }
  return false;
}

export default SettingsBlock;

export { noUser };
